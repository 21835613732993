import React, { useState, useRef, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from './context/AuthContext';
import './Layout.css'; // Assuming you have some CSS for styling

function Layout({ children }) {
    const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const navigate = useNavigate();
    const dropdownRef = useRef(null);

    const handleMouseEnter = () => {
        setDropdownOpen(true);
    };

    const handleMouseLeave = (e) => {
        if (dropdownRef.current) {
            const rect = dropdownRef.current.getBoundingClientRect();
            const isOutside = (
                e.clientX < rect.left - 66 ||
                e.clientX > rect.right + 66 ||
                e.clientY < rect.top - 66 ||
                e.clientY > rect.bottom + 66
            );
            if (isOutside) {
                setDropdownOpen(false);
            }
        }
    };

    const handleSignOut = () => {
        localStorage.removeItem('token');
        setIsLoggedIn(false);
        setDropdownOpen(false);
        navigate('/login');
    };

    return (
        <div className="layout">
            <header className="banner">
                <h1>My App</h1>
                <div className="user-menu">
                    <div
                        className="user-icon"
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        ref={dropdownRef}
                    >
                        <img src="/user-icon.svg" alt="User" />
                        {isDropdownOpen && (
                            <div className="dropdown" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                {isLoggedIn ? (
                                    <>
                                        <Link to="/profile">Profile</Link>
                                        <button onClick={handleSignOut}>Sign Out</button>
                                    </>
                                ) : (
                                    <Link to="/login">Sign In</Link>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </header>
            <div className="main-content">
                {isLoggedIn && (
                    <nav className="navbar">
                        <ul>
                            <li><Link to="/orders">梅西订单</Link></li>
                            <li><Link to="/manual-add">手动加单</Link></li>
                        </ul>
                    </nav>
                )}
                <main className="content">
                    {children}
                </main>
            </div>
        </div>
    );
}

export default Layout; 