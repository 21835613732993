import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Handsontable from 'handsontable';
import { HotTable } from '@handsontable/react';
import 'handsontable/dist/handsontable.full.css';
import { useNavigate } from 'react-router-dom';
import './Orders.css';
import zhCN from 'handsontable/i18n/languages/zh-CN';
import { useAuthRedirect } from './utils/auth'; // Import the utility function
// Import icons
import processingIcon from './assets/icons/processing.svg';
import checkedIcon from './assets/icons/checked.svg';
import config from './config';

Handsontable.cellTypes.registerCellType('numeric', Handsontable.cellTypes.numeric);
Handsontable.languages.registerLanguageDictionary(zhCN);

function convertStreetNumber(streetNumber) {
    // Convert specific letters to numbers and remove others
    return streetNumber.split('').map(char => {
        if (/[oO]/.test(char)) {
            return '0';
        } else if (/[iIlL]/.test(char)) {
            return '1';
        } else if (/[0-9]/.test(char)) {
            return char; // Keep numeric characters
        }
        return ''; // Remove other letters
    }).join('');
}

function Orders() {
    const [orders, setOrders] = useState([]);
    const [message, setMessage] = useState('');
    const hotTableComponent = useRef(null);
    const navigate = useNavigate();
    const authRedirect = useAuthRedirect(); // Use the utility function
    const token = localStorage.getItem('token');

    const fetchOrders = async () => {
        authRedirect(); // Check token before making the API call
        try {
            const response = await axios.get(`${config.apiUrl}/order/orders`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            console.log('Fetched orders:', response.data); // Log fetched data

            const formattedOrders = response.data.map(order => {
                let streetNumber = order.addr1.split(' ')[0]; // Extract street number
                streetNumber = convertStreetNumber(streetNumber); // Convert letters to numbers
                const maskedAddress = `${streetNumber} *****`; // Mask the address
                const lastName = order.ship_to.split(' ').pop(); // Extract last name
                return {
                    ...order,
                    order_date: new Date(order.order_date).toISOString().split('T')[0], // Format date
                    addr1: maskedAddress, // Update address
                    addr2: order.zipcode, // Add zipcode
                    ship_to: lastName, // Update ship_to to only show last name
                    statusText: order.status === 0 ? 'Processing' : 'Completed' // Map status to text in English
                };
            });

            // Sort orders by order_id in descending order
            formattedOrders.sort((a, b) => b.order_id - a.order_id);

            console.log('Formatted and sorted orders:', formattedOrders); // Log formatted and sorted data

            setOrders(formattedOrders);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/login'); // Redirect to login if unauthorized
            } else {
                window.alert('Failed to fetch orders.');
                console.error('Fetch orders error:', error);
            }
        }
    };

    useEffect(() => {
        fetchOrders();
    }, [navigate, token]);

    const refreshOrders = async () => {
        try {
            const response = await axios.put(`${config.apiUrl}/order/update-order-status`, {}, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            console.log('Order statuses updated:', response.data);
            window.alert('订单状态已更新');
            fetchOrders(); // Refresh the orders list after updating
        } catch (error) {
            window.alert('刷新订单失败');
            console.error('Update order statuses error:', error);
        }
    };

    const statusRenderer = (instance, td, row, col, prop, value, cellProperties) => {
        console.log('Rendering status for row:', row, 'value:', value); // Log rendering process
        Handsontable.dom.empty(td); // Clear the cell
        const icon = document.createElement('img');
        icon.style.width = '20px'; // Set icon size
        icon.style.height = '20px';
        if (value === 'Processing') {
            icon.src = processingIcon;
            icon.alt = 'Processing';
        } else if (value === 'Completed') {
            icon.src = checkedIcon;
            icon.alt = 'Completed';
        }
        td.appendChild(icon);
        return td;
    };

    const handleAfterChange = async (changes, source) => {
        if (source === 'loadData' || !Array.isArray(changes)) {
            return; // Don't process changes when loading data or if changes is not an array
        }

        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login'); // Redirect to login if no token
            return;
        }

        changes.forEach(async ([row, prop, oldValue, newValue]) => {
            if (oldValue !== newValue && ['items', 'comment1', 'comment2'].includes(prop)) {
                const orderId = orders[row].order_id;
                try {
                    await axios.put(`${config.apiUrl}/order/${orderId}`, {
                        [prop]: newValue
                    }, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    console.log(`Updated order ${orderId}: ${prop} changed from ${oldValue} to ${newValue}`);
                } catch (error) {
                    console.error(`Failed to update order ${orderId}:`, error);
                    window.alert(`未能更新 ${orderId}.`);
                }
            }
        });
    };

    const formatItems = (itemsJson) => {
        try {
            console.log('Parsing items JSON:', itemsJson); // Debugging line
            const items = JSON.parse(itemsJson);
            return items.map(item => `${item.productId} x ${item.quantity}`).join(', ');
        } catch (error) {
            console.error('Error parsing items JSON:', error);
            return 'Invalid item data';
        }
    };

    const columns = [
        {
            data: 'order_id',
            renderer: function (instance, td, row, col, prop, value, cellProperties) {
                const url = instance.getDataAtRowProp(row, 'url');
                Handsontable.dom.empty(td);
                const link = document.createElement('a');
                link.href = url;
                link.textContent = value;
                link.target = '_blank'; // Open in a new tab
                td.appendChild(link);
                return td;
            },
            readOnly: true
        },
        { data: 'order_date', type: 'text', readOnly: true, filter: true },
        { data: 'amount', type: 'numeric', readOnly: true },
        {
            data: 'items',
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                Handsontable.dom.empty(td);
                const formattedItems = formatItems(value);
                td.textContent = formattedItems;
                return td;
            },

        },
        { data: 'tracking', type: 'text', readOnly: true, filter: true },
        { data: 'ship_to', type: 'text', readOnly: true, filter: true },
        { data: 'addr1', type: 'text', readOnly: true, filter: true }, // Masked address
        { data: 'addr2', type: 'text', readOnly: true, filter: true }, // Zipcode
        {
            data: 'statusText',
            renderer: statusRenderer,
            filter: {
                condition: 'by_value',
                values: ['Processing', 'Completed']
            },
            readOnly: true
        },
        { data: 'comment1', type: 'text' },
        { data: 'comment2', type: 'text' }
    ];

    const beforeCopy = (data, coords) => {
        console.log('beforeCopy triggered');
        coords.forEach(coord => {
            for (let row = coord.startRow; row <= coord.endRow; row++) {
                for (let col = coord.startCol; col <= coord.endCol; col++) {
                    if (col === 0) { // Assuming order_id is the first column
                        const orderId = data[row][col];
                        const url = hotTableComponent.current.hotInstance.getDataAtRowProp(row, 'url');
                        console.log(`Row: ${row}, Order ID: ${orderId}, URL: ${url}`); // Log the values
                        if (orderId && url) {
                            data[row][col] = `=HYPERLINK("${url}", "${orderId}")`;
                            console.log(`Modified data for row ${row}: ${data[row][col]}`);
                        } else {
                            console.log(`No URL or Order ID found for row ${row}`);
                        }
                    }
                }
            }
        });
    };

    return (
        <div className="Orders">
            <button onClick={refreshOrders} className='refresh-button'>刷新订单</button>
            <HotTable
                ref={hotTableComponent}
                data={orders}
                colHeaders={[
                    '订单号', '订单日期', '金额', '商品', '物流',
                    '入库码', '地址', '邮编', '状态', '备注1', '备注2'
                ]}
                columns={columns}
                rowHeaders={true}
                width="100%"
                height="400"
                licenseKey="non-commercial-and-evaluation"
                copyPaste={true}
                beforeCopy={beforeCopy}
                filters={true}
                language="zh-CN"
                manualColumnMove={true}
                dropdownMenu={[
                    'filter_by_condition',
                    'filter_by_value',
                    'filter_action_bar'
                ]}
                contextMenu={true}
                afterChange={handleAfterChange}
                autoRowSize={true}
            />
        </div>
    );
}

export default Orders;