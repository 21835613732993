// src/config.js
const config = {
    development: {
        apiUrl: 'http://localhost:5000'
    },
    production: {
        apiUrl: 'https://ttlbyte.me/api/macys'
    }
};

export default config[process.env.NODE_ENV];