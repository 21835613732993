import React, { useState, useContext } from 'react';
import axios from 'axios';
import './ManualAdd.css'; // Import the CSS file for styling
import { AuthContext } from './context/AuthContext'; // Assuming you have an AuthContext
import { useAuthRedirect } from './utils/auth'; // Import the utility function
import config from './config';

function ManualAdd() {
    const [orderIds, setOrderIds] = useState('');
    const [zipcodes, setZipcodes] = useState('');
    const [status, setStatus] = useState('');
    const { username } = useContext(AuthContext); // Assuming username is available in AuthContext
    const authRedirect = useAuthRedirect(); // Use the utility function

    const handleOrderIdsChange = (e) => {
        const input = e.target.value;
        const sanitizedInput = input.replace(/\D/g, ''); // Remove non-digit characters
        const formattedOrderIds = sanitizedInput.match(/.{1,10}/g)?.join('\n') || ''; // Group into 10-digit chunks
        setOrderIds(formattedOrderIds);
    };

    const handleZipcodesChange = (e) => {
        const input = e.target.value;
        const sanitizedInput = input.replace(/\D/g, ''); // Remove non-digit characters
        const formattedZipcodes = input.match(/.{1,5}/g)?.join('\n') || ''; // Group into 5-digit chunks
        setZipcodes(formattedZipcodes);
    };

    const handleSubmit = async () => {
        authRedirect(); // Check token before making the API call
        const orderIdsArray = orderIds.split('\n').filter(id => id.length === 10);
        const zipcodesArray = zipcodes.split('\n').filter(zip => zip.length === 5);

        if (orderIdsArray.length && zipcodesArray.length) {
            try {
                const token = localStorage.getItem('token'); // Retrieve the token from local storage
                const response = await axios.post(`${config.apiUrl}/order/manual-add`, {
                    username, // Use username instead of user_id
                    order_ids: orderIdsArray,
                    zipcodes: zipcodesArray
                }, {
                    headers: {
                        'Authorization': `Bearer ${token}` // Include the token in the headers
                    }
                });

                const { success, failed, exist } = response.data;
                let statusMessage = '';

                if (success.length > 0) {
                    statusMessage += `成功添加订单: ${success.join(', ')}.\n`;
                }
                if (failed.length > 0) {
                    statusMessage += `错误订单: ${failed.join(', ')}.\n`;
                }
                if (exist.length > 0) {
                    statusMessage += `重复订单: ${exist.join(', ')}.\n`;
                }

                setStatus(statusMessage || 'Orders and zipcodes submitted successfully.');
            } catch (error) {
                setStatus('Failed to submit orders and zipcodes.');
                console.error('Submission error:', error);
            }
        } else {
            setStatus('Please enter valid order IDs and zipcodes.');
        }
    };

    return (
        <div>
            <h2>手动加单</h2>
            <div className="input-container">
                <div className="input-box">
                    <label>订单号</label>
                    <textarea
                        value={orderIds}
                        onChange={handleOrderIdsChange}
                        rows="20"
                        cols="10" // Set width to approximately 12 characters
                        placeholder="输入所有订单号"
                    />
                </div>
                <div className="input-box">
                    <label>Zip Code:</label>
                    <textarea
                        value={zipcodes}
                        onChange={handleZipcodesChange}
                        rows="20"
                        cols="7" // Halve the width to approximately 3 characters
                        placeholder="输入zip code, 重复的仅输入一次"
                    />
                </div>
                <div className="status-box">
                    <label>Status:</label>
                    <textarea
                        value={status}
                        readOnly
                        rows="20"
                        cols="24" // Double the width to approximately 24 characters
                        placeholder="结果"
                    />
                </div>
            </div>
            <button className='add-button' onClick={handleSubmit}>提交订单</button>
        </div>
    );
}

export default ManualAdd; 