import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [username, setUsername] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const fetchUser = async () => {
            const token = localStorage.getItem('token');
            if (token) {
                try {
                    const response = await axios.get('http://127.0.0.1:5000/auth/users/me', {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setUsername(response.data.username);
                    setIsLoggedIn(true);
                } catch (error) {
                    console.error('Failed to fetch user:', error);
                    setIsLoggedIn(false);
                }
            }
        };

        fetchUser();
    }, []);

    return (
        <AuthContext.Provider value={{ username, isLoggedIn, setIsLoggedIn }}>
            {children}
        </AuthContext.Provider>
    );
}; 