import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from './config';

function Home() {
    const [isTokenValid, setIsTokenValid] = useState(null);
    const navigate = useNavigate();
    const token = localStorage.getItem('token');

    useEffect(() => {
        const checkTokenValidity = async () => {
            if (!token) {
                setIsTokenValid(false);
                return;
            }

            try {
                await axios.get(`${config.apiUrl}/auth/users/me`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setIsTokenValid(true);
            } catch (error) {
                console.error('Token verification failed:', error);
                setIsTokenValid(false);
            }
        };

        checkTokenValidity();
    }, [token]);

    useEffect(() => {
        if (isTokenValid === true) {
            navigate('/orders');
        } else if (isTokenValid === false) {
            navigate('/login');
        }
    }, [isTokenValid, navigate]);

    return (
        <div>
            {isTokenValid === null && <p>Loading...</p>}
        </div>
    );
}

export default Home; 