import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './context/AuthContext';
import './SignIn.css';
import config from './config';

function SignIn() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const { setIsLoggedIn } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleSignIn = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post(`${config.apiUrl}/auth/token`, {
                username: username,
                password: password
            });
            localStorage.setItem('token', response.data.access_token);
            setIsLoggedIn(true);
            navigate('/orders');
        } catch (error) {
            window.alert('Sign in failed. Please check your credentials.');
            console.error('Sign in error:', error);
        }
    };

    return (
        <div className="signin-container">
            <form className="signin-form" onSubmit={handleSignIn}>
                <h2>Login</h2>
                <div className="form-group">
                    <label htmlFor="username">Username:</label>
                    <input
                        type="text"
                        id="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password:</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <div className="user-icon">
                    <button type="submit">Sign In</button>
                </div>
            </form>
        </div>
    );
}

export default SignIn; 