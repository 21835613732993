import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import './Profile.css'; // Import the CSS file for styling
import { AuthContext } from './context/AuthContext'; // Assuming you have an AuthContext
import config from './config';
function Profile() {
    const { username } = useContext(AuthContext); // Assuming username is available in AuthContext
    const [fullName, setFullName] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        // Fetch user data from /users/me
        const fetchUserData = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${config.apiUrl}/auth/users/me`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setFullName(response.data.full_name);
            } catch (error) {
                console.error('Failed to fetch user data:', error);
            }
        };

        fetchUserData();
    }, []);

    const handleFullNameChange = (e) => {
        setFullName(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setNewPassword(e.target.value);
    };

    const handleRepeatPasswordChange = (e) => {
        setRepeatPassword(e.target.value);
    };

    const handleCurrentPasswordChange = (e) => {
        setCurrentPassword(e.target.value);
    };

    const validatePassword = (password) => {
        // Enforce password complexity: at least 8 characters, one uppercase, one lowercase, one number
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
        return regex.test(password);
    };

    const handleFullNameUpdate = async () => {
        try {
            const token = localStorage.getItem('token');
            await axios.put(`${config.apiUrl}/auth/update-fullname`, {
                fullName
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setIsEditing(false);
            setMessage('Full name updated successfully.');
        } catch (error) {
            setMessage('Failed to update full name.');
            console.error('Full name update error:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validatePassword(newPassword)) {
            setMessage('Password must be at least 8 characters long and include an uppercase letter, a lowercase letter, and a number.');
            return;
        }
        if (newPassword !== repeatPassword) {
            setMessage('New password and repeat password do not match.');
            return;
        }
        try {
            const token = localStorage.getItem('token');
            await axios.put(`${config.apiUrl}/auth/update-password`, {
                currentPassword,
                newPassword
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setMessage('Password reset successfully.');
        } catch (error) {
            setMessage('Failed to reset password.');
            console.error('Password reset error:', error);
        }
    };

    return (
        <div className="profile-container">
            <h2>Profile</h2>
            <div className="profile-info">
                <p><strong>Username:</strong> {username}</p>
                <div>
                    <strong>Full Name:</strong>
                    {isEditing ? (
                        <input
                            type="text"
                            value={fullName}
                            onChange={handleFullNameChange}
                            onBlur={handleFullNameUpdate}
                            placeholder="Enter your full name"
                        />
                    ) : (
                        <span>{fullName}</span>
                    )}
                    <button onClick={() => setIsEditing(!isEditing)}>
                        {isEditing ? 'Save' : 'Edit'}
                    </button>
                </div>
            </div>
            <div className="reset-password">
                <h3>Reset Password</h3>
                <form onSubmit={handleSubmit}>
                    <label>
                        Current Password:
                        <input
                            type="password"
                            value={currentPassword}
                            onChange={handleCurrentPasswordChange}
                            required
                        />
                    </label>
                    <label>
                        New Password:
                        <input
                            type="password"
                            value={newPassword}
                            onChange={handlePasswordChange}
                            required
                        />
                    </label>
                    <label>
                        Repeat New Password:
                        <input
                            type="password"
                            value={repeatPassword}
                            onChange={handleRepeatPasswordChange}
                            required
                        />
                    </label>
                    <button type="submit">Reset Password</button>
                </form>
                {message && <p className="message">{message}</p>}
            </div>
        </div>
    );
}

export default Profile; 