import { useNavigate } from 'react-router-dom';

export const checkTokenExpiration = () => {
    const token = localStorage.getItem('token');
    if (!token) {
        return false;
    }

    try {
        const tokenPayload = JSON.parse(atob(token.split('.')[1]));
        const isExpired = tokenPayload.exp * 1000 < Date.now();
        if (isExpired) {
            localStorage.removeItem('token');
            return false;
        }
        return true;
    } catch (error) {
        console.error('Invalid token:', error);
        localStorage.removeItem('token');
        return false;
    }
};

export const useAuthRedirect = () => {
    const navigate = useNavigate();
    return () => {
        if (!checkTokenExpiration()) {
            navigate('/login');
        }
    };
};