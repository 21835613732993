import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home';
import Orders from './Orders';
import SignIn from './SignIn';
import ManualAdd from './ManualAdd';
import Layout from './Layout';
import { AuthProvider } from './context/AuthContext';
import Profile from './Profile';

function App() {
    return (
        <AuthProvider>
            <Router>
                <Layout>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/orders" element={<Orders />} />
                        <Route path="/login" element={<SignIn />} />
                        <Route path="/manual-add" element={<ManualAdd />} />
                        <Route path="/profile" element={<Profile />} />
                    </Routes>
                </Layout>
            </Router>
        </AuthProvider>
    );
}

export default App; 